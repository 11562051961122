@import "../../mixins.scss";

.benefits-of-massage {
  margin-top: 0;
  background: url("../../assets/images/benefits-bg.webp") center center no-repeat;
  background-size: cover;
  padding: 105px 0;
  .label {
    @include label();
    color: var(--primary-color);
    text-align: center;
  }

  h2 {
    @include heading2();
    color: var(--primary-text);
    text-align: center;
    margin: 20px auto 30px auto;
  }

  .content{
    position: relative;
  }

  @media (min-width: 1290px) {
    .content {
      text-align: center;
      max-width: 1290px;
      margin: auto;
      img {
        width: 500px;
        height: auto;
      }

      .main {
        position: absolute;
        top: 0;
        z-index: 2;

        .benefit-card {
          position: absolute;
          top: 0;
          left: 0;
          background-color: #fff;
          padding: 15px 25px;
          border-radius: 50px 50px 0 50px;
          min-width: 320px;
          max-width: 320px;
          text-align: center;
          h4 {
            @include heading2();
            color: #222;
            font-size: 1.8rem;
          }

          p {
            @include para();
            color: #666;
            font-size: 1.2rem;
          }
        }

        .one {
          top: 0;
          left: 130px;
        }

        .two {
          top: 220px;
          left: 50px;
        }

        .three {
          top: 460px;
          left: 140px;
        }

        .four {
          top: 0;
          left: 50px;
          border-radius: 0 50px 50px 50px;
          transform: translateX(800px);
        }

        .five {
          top: 220px;
          left: 130px;
          border-radius: 0 50px 50px 50px;
          transform: translateX(800px);
        }

        .six {
          top: 460px;
          left: 40px;
          border-radius: 0 50px 50px 50px;
          transform: translateX(800px);
        }
      }

      .bg-pattern {
        background: url("../../assets/images/benefits-shape.svg") center center
          no-repeat;
        height: 450px;
        width: 450px;
        position: absolute;
        top: 62px;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 100%;
        z-index: -1;
        animation: rotate linear 150s infinite backwards;
      }
    }
  }

  @media (max-width: 1290px) {
    .content {
      width: 100%;
      @include flex();
      flex-direction: column;
      position: relative;
      padding: 0 5%;

      .main {
        .benefit-card {
          margin: 25px auto;
          background-color: #fff;
          padding: 15px 25px;
          border-radius: 50px 50px 0 50px;
          min-width: 100%;
          max-width: 100%;
          text-align: center;
          h4 {
            @include heading2();
            color: #222;
            font-size: 1.8rem;
          }

          p {
            @include para();
            color: #666;
            font-size: 1.2rem;
          }
        }
      }

      .bg-pattern {
        background: url("../../assets/images/benefits-shape.svg") center center
          no-repeat;
        height: 450px;
        width: 450px;
        position: absolute;
        top: 62px;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 100%;
        z-index: -1;
        animation: rotate linear 150s infinite backwards;
      }
    }
  }

  @media (max-width: 700px) {
    .content {
      .main {
        .benefit-card {
          padding: 15px 25px;
          h4 {
            font-size: 2.2rem;
          }

          p {
            font-size: 1.2rem;
          }
        }
      }

      .bg-pattern {
        background: url("../../assets/images/benefits-shape.svg") center center
          no-repeat;
        height: 450px;
        width: 450px;
        position: absolute;
        top: 62px;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 100%;
        z-index: -1;
        animation: rotate linear 150s infinite backwards;
      }
    }
  }
}
